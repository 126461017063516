export const CURRENT_CONFIG = {

  // license
  appId: '140435', // You need to go to the development website to apply.
  appKey: '5a22e266907e9dc772482da97351f66', // You need to go to the development website to apply.
  appLicense: 'vOh3Mp4EIAG++bMTMLBAQb35A0wAyZha4B1+vHtEuJfNvk33LpfPfnThu+IyFIKS62USVoGxC5atgUkuAGxrnd7Y5yE6P5ebuJFffY9yFS/Mn76Gk4sv7aqugsYZWQJLAYA+pkXz5VQ4Zn77KI64sZQqutaVbah7RIbTo8QetC0=', // You need to go to the development website to apply.

  // http
  baseURL: 'https://api.termaldrone.dns.army/', // This url must end with "/". Example: 'http://192.168.1.1:6789/'
  websocketURL: 'ws://api.termaldrone.dns.army:6789/api/v1/ws', // Example: 'ws://192.168.1.1:6789/api/v1/ws'

  // livestreaming
  // RTMP  Note: This IP is the address of the streaming server. If you want to see livestream on web page, you need to convert the RTMP stream to WebRTC stream.
  rtmpURL: 'rtmp://localhost/live', // Example: 'rtmp://192.168.1.1/live/'
  // GB28181 Note:If you don't know what these parameters mean, you can go to Pilot2 and select the GB28181 page in the cloud platform. Where the parameters same as these parameters.
  gbServerIp: 'Please enter the server ip.',
  gbServerPort: 'Please enter the server port.',
  gbServerId: 'Please enter the server id.',
  gbAgentId: 'Please enter the agent id',
  gbPassword: 'Please enter the agent password',
  gbAgentPort: 'Please enter the local port.',
  gbAgentChannel: 'Please enter the channel.',
  // RTSP
  rtspUserName: 'termaldrone',
  rtspPassword: 'termaldrone',
  rtspPort: '8554',
  // Agora
  agoraAPPID: 'Please enter the agora app id.',
  agoraToken: 'Please enter the agora temporary token.',
  agoraChannel: 'Please enter the agora channel.',

  // map
  // You can apply on the AMap website.
  amapKey: 'Please enter the amap key.',

}
